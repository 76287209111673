<template>
  <div class="map-container">
    <div class="toolbar">
      <button @click="goBack" :disabled="mapData.stack.length === 0">返回上一级</button>
      <span>{{ mapData.currentName }}</span>
    </div>
    <div ref="chartRef" class="chart"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {ref, onMounted} from "vue";
import axios from "axios";

export default {
  name: "EChartsMap",
  setup() {
    const chartRef = ref(null); // 图表 DOM 引用
    const chartInstance = ref(null); // ECharts 实例
    const mapData = ref({
      currentLevel: "中华人民共和国", // 当前地图层级：china, province, city
      currentCode: "100000", // 当前区域的 code
      currentName: "中国", // 当前区域名
      stack: [], // 记录上钻路径
    });

    // 初始化地图
    const initChart = () => {
      chartInstance.value = echarts.init(chartRef.value);
      loadMap("100000", "中国");
      chartInstance.value.getZr().on("click", (event) => {
        if (event.target) {
          console.log('点击了地图')
          chartInstance.value.off('click')
          chartInstance.value.on("click", handleMapClick);
        } else {
          console.log('点击了空白区域')
          goBack()
        }
      })
    };

    // 获取 GeoJSON 数据
    const getGeoJson = async (code) => {
      const res =  await axios.post('https://oa.yanghualaw.com/api/SuSong/GeoJson', {
        'adcode': code
      });
      return res.data
    };

    // eslint-disable-next-line no-unused-vars
    const createMarkPoint = (item) => {
      return {
        name: item.Name, // 显示名称
        coord: item.lnglat ? item.lnglat.split(',') : [], // 坐标
        symbol: markerIconStyle[item.style].symbol, // 标记类型
        symbolSize: 10,
        tooltip: {
          formatter: "{b}"
        }
      };
    };
    const markerIconStyle = {
      2: {
        symbol: 'image://https://share-1308478496.cos.ap-chongqing.myqcloud.com/blue-flag.png', // 可以是 'rect'、'circle'、'triangle' 等标记
      },
      1: {
        symbol: 'image://https://share-1308478496.cos.ap-chongqing.myqcloud.com/red-flag.png',
      }
    };

    // 加载地图数据
    const loadMap = async (code, name) => {
      try {
        chartInstance.value.showLoading();
        // const url = getGeoJsonUrl(code);
        const response = await getGeoJson(code);
        const geoJson = response.data;
        // 计算中心点和缩放级别

        const res = await axios.post('https://oa.yanghualaw.com/api/SuSong/Points', {
          'adcode': code
        })


        // eslint-disable-next-line no-unused-vars
        const points = res.data.data

        echarts.registerMap(name, geoJson);
        const newMapData = geoJson.features.map((feature) => ({
          name: feature.properties.name,
          value: Math.random() * 1000, // 示例数据
          adcode: feature.properties.adcode, // 显式绑定 adcode
        }));


        chartInstance.value.setOption({
          title: {text: name, left: "center"},
          tooltip: {trigger: "item", formatter: "{b}"},
          geo: {
            center: null,
            map: name,
            roam: true,
            zoom: 1,
            itemStyle: {
              // 地图区域的颜色
              areaColor: "#ffffff", // 绿色
              // 图形的描边颜色
              borderColor: "#b0c5ef" // 蓝色
            },
            emphasis: {
              // 设置区域样式
              itemStyle: {
                areaColor: "#efdbdb", // 黄色
                borderColor: "#f20000" // 描边颜色黄色
              },
              // 设置字体
              label: {
                fontSize: 16, // 16px
                color: "#ff0000" // 白色
              }
            },
            label: {
              show: true,
              color: "#151515",
              fontSize: 12
            }
          },

          series: [
            {
              type: "map",
              map: name,
              roam: true,
              center: null,
              zoom: 1,
              selectedMode: false,
              geoIndex: 0,
              data: newMapData, // 区域数据（可按需求添加）
              markPoint: {
                data: points.map((item) => createMarkPoint(item))
              }
            }
          ]
        });

        mapData.value.currentLevel = code.length === 2 ? "province" : code.length === 4 ? "city" : "district";
        mapData.value.currentCode = code;
        mapData.value.currentName = name;

        chartInstance.value.hideLoading();
      } catch (error) {
        chartInstance.value.hideLoading();
        console.error("加载地图失败", error);
      }
    };

    // 处理点击事件，切换到下一级地图
    const handleMapClick = async (event) => {
      const {name, data} = event;
      if (name.includes('法院')) return;
      if (!data) return;
      if (mapData.value.stack.length == 2) return;
      // 台湾 澳门 香港
      if ([710000, 820000, 810000].includes(data.adcode)) return;

      const nextCode = `${data.adcode}`; // 假设数据中包含区域的 code
      if (!nextCode) return;
      mapData.value.stack.push({code: mapData.value.currentCode, name: mapData.value.currentName});

      await loadMap(nextCode, name);

    };

    // 返回上一级
    const goBack = async () => {
      if (mapData.value.stack.length === 0) return;
      const last = mapData.value.stack.pop();
      await loadMap(last.code, last.name);
    };

    onMounted(initChart);

    return {chartRef, goBack, mapData};
  },
};
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 100%;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.chart {
  width: 100vw;
  height: calc(100vh - 50px);
}
</style>
